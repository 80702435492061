<template>
     <div class="error-page">
        <div class="error-box">
				<div class="error-img">
                    <img src="@/assets/img/authentication/under-maintenance.png" class="img-fluid" alt="">
                </div>
				<h3 class="h2 mb-3">We are Under Maintenance</h3>
				<p>Sorry for any inconvenience caused, we have almost done 
                    Will get back soon!</p>
				<router-link to="/dashboard" class="btn btn-primary">Back to Dashboard</router-link>
			</div>
     </div>
</template>