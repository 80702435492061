<template>
  <!-- Main Wrapper -->
  
    <layout-header></layout-header>
    <layout-sidebar></layout-sidebar>

    <!-- Page Wrapper -->
    <div class="page-wrapper">
      <div class="content container-fluid">
        <div class="row">
          <!-- Chart -->
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">Pe7 Icon</h5>
              </div>
              <div class="card-body">
                <div class="icons-items">
                  <ul class="icons-list">
                    <li>
                      <i
                        class="pe-7s-album"
                        data-bs-toggle="tooltip"
                        title="pe-7s-album"
                      ></i>
                    </li>
                    <li>
                      <i class="pe-7s-arc" data-bs-toggle="tooltip" title="pe-7s-arc"></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-back-2"
                        data-bs-toggle="tooltip"
                        title="pe-7s-back-2"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-bandaid"
                        data-bs-toggle="tooltip"
                        title="pe-7s-bandaid"
                      ></i>
                    </li>
                    <li>
                      <i class="pe-7s-car" data-bs-toggle="tooltip" title="pe-7s-car"></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-diamond"
                        data-bs-toggle="tooltip"
                        title="pe-7s-diamond"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-door-lock"
                        data-bs-toggle="tooltip"
                        title="pe-7s-door-lock"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-eyedropper"
                        data-bs-toggle="tooltip"
                        title="pe-7s-eyedropper"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-female"
                        data-bs-toggle="tooltip"
                        title="pe-7s-female"
                      ></i>
                    </li>
                    <li>
                      <i class="pe-7s-gym" data-bs-toggle="tooltip" title="pe-7s-gym"></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-hammer"
                        data-bs-toggle="tooltip"
                        title="pe-7s-hammer"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-headphones"
                        data-bs-toggle="tooltip"
                        title="pe-7s-headphones"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-helm"
                        data-bs-toggle="tooltip"
                        title="pe-7s-helm"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-hourglass"
                        data-bs-toggle="tooltip"
                        title="pe-7s-hourglass"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-leaf"
                        data-bs-toggle="tooltip"
                        title="pe-7s-leaf"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-magic-wand"
                        data-bs-toggle="tooltip"
                        title="pe-7s-magic-wand"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-male"
                        data-bs-toggle="tooltip"
                        title="pe-7s-male"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-map-2"
                        data-bs-toggle="tooltip"
                        title="pe-7s-map-2"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-next-2"
                        data-bs-toggle="tooltip"
                        title="pe-7s-next-2"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-paint-bucket"
                        data-bs-toggle="tooltip"
                        title="pe-7s-paint-bucket"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-pendrive"
                        data-bs-toggle="tooltip"
                        title="pe-7s-pendrive"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-photo"
                        data-bs-toggle="tooltip"
                        title="pe-7s-photo"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-piggy"
                        data-bs-toggle="tooltip"
                        title="pe-7s-piggy"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-plugin"
                        data-bs-toggle="tooltip"
                        title="pe-7s-plugin"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-refresh-2"
                        data-bs-toggle="tooltip"
                        title="pe-7s-refresh-2"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-rocket"
                        data-bs-toggle="tooltip"
                        title="pe-7s-rocket"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-settings"
                        data-bs-toggle="tooltip"
                        title="pe-7s-settings"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-shield"
                        data-bs-toggle="tooltip"
                        title="pe-7s-shield"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-smile"
                        data-bs-toggle="tooltip"
                        title="pe-7s-smile"
                      ></i>
                    </li>
                    <li>
                      <i class="pe-7s-usb" data-bs-toggle="tooltip" title="pe-7s-usb"></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-vector"
                        data-bs-toggle="tooltip"
                        title="pe-7s-vector"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-wine"
                        data-bs-toggle="tooltip"
                        title="pe-7s-wine"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-cloud-upload"
                        data-bs-toggle="tooltip"
                        title="pe-7s-cloud-upload"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-cash"
                        data-bs-toggle="tooltip"
                        title="pe-7s-cash"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-close"
                        data-bs-toggle="tooltip"
                        title="pe-7s-close"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-bluetooth"
                        data-bs-toggle="tooltip"
                        title="pe-7s-bluetooth"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-cloud-download"
                        data-bs-toggle="tooltip"
                        title="pe-7s-cloud-download"
                      ></i>
                    </li>
                    <li>
                      <i class="pe-7s-way" data-bs-toggle="tooltip" title="pe-7s-way"></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-close-circle"
                        data-bs-toggle="tooltip"
                        title="pe-7s-close-circle"
                      ></i>
                    </li>
                    <li>
                      <i class="pe-7s-id" data-bs-toggle="tooltip" title="pe-7s-id"></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-angle-up"
                        data-bs-toggle="tooltip"
                        title="pe-7s-angle-up"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-wristwatch"
                        data-bs-toggle="tooltip"
                        title="pe-7s-wristwatch"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-angle-up-circle"
                        data-bs-toggle="tooltip"
                        title="pe-7s-angle-up-circle"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-world"
                        data-bs-toggle="tooltip"
                        title="pe-7s-world"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-angle-right"
                        data-bs-toggle="tooltip"
                        title="pe-7s-angle-right"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-volume"
                        data-bs-toggle="tooltip"
                        title="pe-7s-volume"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-angle-right-circle"
                        data-bs-toggle="tooltip"
                        title="pe-7s-angle-right-circle"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-users"
                        data-bs-toggle="tooltip"
                        title="pe-7s-users"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-angle-left"
                        data-bs-toggle="tooltip"
                        title="pe-7s-angle-left"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-user-female"
                        data-bs-toggle="tooltip"
                        title="pe-7s-user-female"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-angle-left-circle"
                        data-bs-toggle="tooltip"
                        title="pe-7s-angle-left-circle"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-up-arrow"
                        data-bs-toggle="tooltip"
                        title="pe-7s-up-arrow"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-angle-down"
                        data-bs-toggle="tooltip"
                        title="pe-7s-angle-down"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-switch"
                        data-bs-toggle="tooltip"
                        title="pe-7s-switch"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-angle-down-circle"
                        data-bs-toggle="tooltip"
                        title="pe-7s-angle-down-circle"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-scissors"
                        data-bs-toggle="tooltip"
                        title="pe-7s-scissors"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-wallet"
                        data-bs-toggle="tooltip"
                        title="pe-7s-wallet"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-safe"
                        data-bs-toggle="tooltip"
                        title="pe-7s-safe"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-volume2"
                        data-bs-toggle="tooltip"
                        title="pe-7s-volume2"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-volume1"
                        data-bs-toggle="tooltip"
                        title="pe-7s-volume1"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-voicemail"
                        data-bs-toggle="tooltip"
                        title="pe-7s-voicemail"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-video"
                        data-bs-toggle="tooltip"
                        title="pe-7s-video"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-user"
                        data-bs-toggle="tooltip"
                        title="pe-7s-user"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-upload"
                        data-bs-toggle="tooltip"
                        title="pe-7s-upload"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-unlock"
                        data-bs-toggle="tooltip"
                        title="pe-7s-unlock"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-umbrella"
                        data-bs-toggle="tooltip"
                        title="pe-7s-umbrella"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-trash"
                        data-bs-toggle="tooltip"
                        title="pe-7s-trash"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-tools"
                        data-bs-toggle="tooltip"
                        title="pe-7s-tools"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-timer"
                        data-bs-toggle="tooltip"
                        title="pe-7s-timer"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-ticket"
                        data-bs-toggle="tooltip"
                        title="pe-7s-ticket"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-target"
                        data-bs-toggle="tooltip"
                        title="pe-7s-target"
                      ></i>
                    </li>
                    <li>
                      <i class="pe-7s-sun" data-bs-toggle="tooltip" title="pe-7s-sun"></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-study"
                        data-bs-toggle="tooltip"
                        title="pe-7s-study"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-stopwatch"
                        data-bs-toggle="tooltip"
                        title="pe-7s-stopwatch"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-star"
                        data-bs-toggle="tooltip"
                        title="pe-7s-star"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-speaker"
                        data-bs-toggle="tooltip"
                        title="pe-7s-speaker"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-signal"
                        data-bs-toggle="tooltip"
                        title="pe-7s-signal"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-shuffle"
                        data-bs-toggle="tooltip"
                        title="pe-7s-shuffle"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-shopbag"
                        data-bs-toggle="tooltip"
                        title="pe-7s-shopbag"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-share"
                        data-bs-toggle="tooltip"
                        title="pe-7s-share"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-server"
                        data-bs-toggle="tooltip"
                        title="pe-7s-server"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-search"
                        data-bs-toggle="tooltip"
                        title="pe-7s-search"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-film"
                        data-bs-toggle="tooltip"
                        title="pe-7s-film"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-science"
                        data-bs-toggle="tooltip"
                        title="pe-7s-science"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-disk"
                        data-bs-toggle="tooltip"
                        title="pe-7s-disk"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-ribbon"
                        data-bs-toggle="tooltip"
                        title="pe-7s-ribbon"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-repeat"
                        data-bs-toggle="tooltip"
                        title="pe-7s-repeat"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-refresh"
                        data-bs-toggle="tooltip"
                        title="pe-7s-refresh"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-add-user"
                        data-bs-toggle="tooltip"
                        title="pe-7s-add-user"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-refresh-cloud"
                        data-bs-toggle="tooltip"
                        title="pe-7s-refresh-cloud"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-paperclip"
                        data-bs-toggle="tooltip"
                        title="pe-7s-paperclip"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-radio"
                        data-bs-toggle="tooltip"
                        title="pe-7s-radio"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-note2"
                        data-bs-toggle="tooltip"
                        title="pe-7s-note2"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-print"
                        data-bs-toggle="tooltip"
                        title="pe-7s-print"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-network"
                        data-bs-toggle="tooltip"
                        title="pe-7s-network"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-prev"
                        data-bs-toggle="tooltip"
                        title="pe-7s-prev"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-mute"
                        data-bs-toggle="tooltip"
                        title="pe-7s-mute"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-power"
                        data-bs-toggle="tooltip"
                        title="pe-7s-power"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-medal"
                        data-bs-toggle="tooltip"
                        title="pe-7s-medal"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-portfolio"
                        data-bs-toggle="tooltip"
                        title="pe-7s-portfolio"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-like2"
                        data-bs-toggle="tooltip"
                        title="pe-7s-like2"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-plus"
                        data-bs-toggle="tooltip"
                        title="pe-7s-plus"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-left-arrow"
                        data-bs-toggle="tooltip"
                        title="pe-7s-left-arrow"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-play"
                        data-bs-toggle="tooltip"
                        title="pe-7s-play"
                      ></i>
                    </li>
                    <li>
                      <i class="pe-7s-key" data-bs-toggle="tooltip" title="pe-7s-key"></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-plane"
                        data-bs-toggle="tooltip"
                        title="pe-7s-plane"
                      ></i>
                    </li>
                    <li>
                      <i class="pe-7s-joy" data-bs-toggle="tooltip" title="pe-7s-joy"></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-photo-gallery"
                        data-bs-toggle="tooltip"
                        title="pe-7s-photo-gallery"
                      ></i>
                    </li>
                    <li>
                      <i class="pe-7s-pin" data-bs-toggle="tooltip" title="pe-7s-pin"></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-phone"
                        data-bs-toggle="tooltip"
                        title="pe-7s-phone"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-plug"
                        data-bs-toggle="tooltip"
                        title="pe-7s-plug"
                      ></i>
                    </li>
                    <li>
                      <i class="pe-7s-pen" data-bs-toggle="tooltip" title="pe-7s-pen"></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-right-arrow"
                        data-bs-toggle="tooltip"
                        title="pe-7s-right-arrow"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-paper-plane"
                        data-bs-toggle="tooltip"
                        title="pe-7s-paper-plane"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-delete-user"
                        data-bs-toggle="tooltip"
                        title="pe-7s-delete-user"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-paint"
                        data-bs-toggle="tooltip"
                        title="pe-7s-paint"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-bottom-arrow"
                        data-bs-toggle="tooltip"
                        title="pe-7s-bottom-arrow"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-notebook"
                        data-bs-toggle="tooltip"
                        title="pe-7s-notebook"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-note"
                        data-bs-toggle="tooltip"
                        title="pe-7s-note"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-next"
                        data-bs-toggle="tooltip"
                        title="pe-7s-next"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-news-paper"
                        data-bs-toggle="tooltip"
                        title="pe-7s-news-paper"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-musiclist"
                        data-bs-toggle="tooltip"
                        title="pe-7s-musiclist"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-music"
                        data-bs-toggle="tooltip"
                        title="pe-7s-music"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-mouse"
                        data-bs-toggle="tooltip"
                        title="pe-7s-mouse"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-more"
                        data-bs-toggle="tooltip"
                        title="pe-7s-more"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-moon"
                        data-bs-toggle="tooltip"
                        title="pe-7s-moon"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-monitor"
                        data-bs-toggle="tooltip"
                        title="pe-7s-monitor"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-micro"
                        data-bs-toggle="tooltip"
                        title="pe-7s-micro"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-menu"
                        data-bs-toggle="tooltip"
                        title="pe-7s-menu"
                      ></i>
                    </li>
                    <li>
                      <i class="pe-7s-map" data-bs-toggle="tooltip" title="pe-7s-map"></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-map-marker"
                        data-bs-toggle="tooltip"
                        title="pe-7s-map-marker"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-mail"
                        data-bs-toggle="tooltip"
                        title="pe-7s-mail"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-mail-open"
                        data-bs-toggle="tooltip"
                        title="pe-7s-mail-open"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-mail-open-file"
                        data-bs-toggle="tooltip"
                        title="pe-7s-mail-open-file"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-magnet"
                        data-bs-toggle="tooltip"
                        title="pe-7s-magnet"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-loop"
                        data-bs-toggle="tooltip"
                        title="pe-7s-loop"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-look"
                        data-bs-toggle="tooltip"
                        title="pe-7s-look"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-lock"
                        data-bs-toggle="tooltip"
                        title="pe-7s-lock"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-lintern"
                        data-bs-toggle="tooltip"
                        title="pe-7s-lintern"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-link"
                        data-bs-toggle="tooltip"
                        title="pe-7s-link"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-like"
                        data-bs-toggle="tooltip"
                        title="pe-7s-like"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-light"
                        data-bs-toggle="tooltip"
                        title="pe-7s-light"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-less"
                        data-bs-toggle="tooltip"
                        title="pe-7s-less"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-keypad"
                        data-bs-toggle="tooltip"
                        title="pe-7s-keypad"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-junk"
                        data-bs-toggle="tooltip"
                        title="pe-7s-junk"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-info"
                        data-bs-toggle="tooltip"
                        title="pe-7s-info"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-home"
                        data-bs-toggle="tooltip"
                        title="pe-7s-home"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-help2"
                        data-bs-toggle="tooltip"
                        title="pe-7s-help2"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-help1"
                        data-bs-toggle="tooltip"
                        title="pe-7s-help1"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-graph3"
                        data-bs-toggle="tooltip"
                        title="pe-7s-graph3"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-graph2"
                        data-bs-toggle="tooltip"
                        title="pe-7s-graph2"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-graph1"
                        data-bs-toggle="tooltip"
                        title="pe-7s-graph1"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-graph"
                        data-bs-toggle="tooltip"
                        title="pe-7s-graph"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-global"
                        data-bs-toggle="tooltip"
                        title="pe-7s-global"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-gleam"
                        data-bs-toggle="tooltip"
                        title="pe-7s-gleam"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-glasses"
                        data-bs-toggle="tooltip"
                        title="pe-7s-glasses"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-gift"
                        data-bs-toggle="tooltip"
                        title="pe-7s-gift"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-folder"
                        data-bs-toggle="tooltip"
                        title="pe-7s-folder"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-flag"
                        data-bs-toggle="tooltip"
                        title="pe-7s-flag"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-filter"
                        data-bs-toggle="tooltip"
                        title="pe-7s-filter"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-file"
                        data-bs-toggle="tooltip"
                        title="pe-7s-file"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-expand1"
                        data-bs-toggle="tooltip"
                        title="pe-7s-expand1"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-exapnd2"
                        data-bs-toggle="tooltip"
                        title="pe-7s-exapnd2"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-edit"
                        data-bs-toggle="tooltip"
                        title="pe-7s-edit"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-drop"
                        data-bs-toggle="tooltip"
                        title="pe-7s-drop"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-drawer"
                        data-bs-toggle="tooltip"
                        title="pe-7s-drawer"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-download"
                        data-bs-toggle="tooltip"
                        title="pe-7s-download"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-display2"
                        data-bs-toggle="tooltip"
                        title="pe-7s-display2"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-display1"
                        data-bs-toggle="tooltip"
                        title="pe-7s-display1"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-diskette"
                        data-bs-toggle="tooltip"
                        title="pe-7s-diskette"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-date"
                        data-bs-toggle="tooltip"
                        title="pe-7s-date"
                      ></i>
                    </li>
                    <li>
                      <i class="pe-7s-cup" data-bs-toggle="tooltip" title="pe-7s-cup"></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-culture"
                        data-bs-toggle="tooltip"
                        title="pe-7s-culture"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-crop"
                        data-bs-toggle="tooltip"
                        title="pe-7s-crop"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-credit"
                        data-bs-toggle="tooltip"
                        title="pe-7s-credit"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-copy-file"
                        data-bs-toggle="tooltip"
                        title="pe-7s-copy-file"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-config"
                        data-bs-toggle="tooltip"
                        title="pe-7s-config"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-compass"
                        data-bs-toggle="tooltip"
                        title="pe-7s-compass"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-comment"
                        data-bs-toggle="tooltip"
                        title="pe-7s-comment"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-coffee"
                        data-bs-toggle="tooltip"
                        title="pe-7s-coffee"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-cloud"
                        data-bs-toggle="tooltip"
                        title="pe-7s-cloud"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-clock"
                        data-bs-toggle="tooltip"
                        title="pe-7s-clock"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-check"
                        data-bs-toggle="tooltip"
                        title="pe-7s-check"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-chat"
                        data-bs-toggle="tooltip"
                        title="pe-7s-chat"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-cart"
                        data-bs-toggle="tooltip"
                        title="pe-7s-cart"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-camera"
                        data-bs-toggle="tooltip"
                        title="pe-7s-camera"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-call"
                        data-bs-toggle="tooltip"
                        title="pe-7s-call"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-calculator"
                        data-bs-toggle="tooltip"
                        title="pe-7s-calculator"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-browser"
                        data-bs-toggle="tooltip"
                        title="pe-7s-browser"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-box2"
                        data-bs-toggle="tooltip"
                        title="pe-7s-box2"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-box1"
                        data-bs-toggle="tooltip"
                        title="pe-7s-box1"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-bookmarks"
                        data-bs-toggle="tooltip"
                        title="pe-7s-bookmarks"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-bicycle"
                        data-bs-toggle="tooltip"
                        title="pe-7s-bicycle"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-bell"
                        data-bs-toggle="tooltip"
                        title="pe-7s-bell"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-battery"
                        data-bs-toggle="tooltip"
                        title="pe-7s-battery"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-ball"
                        data-bs-toggle="tooltip"
                        title="pe-7s-ball"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-back"
                        data-bs-toggle="tooltip"
                        title="pe-7s-back"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-attention"
                        data-bs-toggle="tooltip"
                        title="pe-7s-attention"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-anchor"
                        data-bs-toggle="tooltip"
                        title="pe-7s-anchor"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-albums"
                        data-bs-toggle="tooltip"
                        title="pe-7s-albums"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-alarm"
                        data-bs-toggle="tooltip"
                        title="pe-7s-alarm"
                      ></i>
                    </li>
                    <li>
                      <i
                        class="pe-7s-airplay"
                        data-bs-toggle="tooltip"
                        title="pe-7s-airplay"
                      ></i>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!-- /Chart -->
        </div>
      </div>
    </div>
    <!-- /Page Wrapper -->
 
  <!-- /Main Wrapper -->
</template>
<script>
export default {
  data() {
    return {
      title: "Pe7 Icon",
    };
  },
};
</script>
