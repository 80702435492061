<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>
  <div class="page-wrapper cardhead">
    <div class="content container-fluid">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <h3 class="page-title">Spinner</h3>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <div class="row">
        <!-- Spinner -->
        <div class="col-md-6">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title">Border Spinner</h5>
            </div>
            <div class="card-body">
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>
        <!-- /Spinner -->

        <!-- Colored Spinner -->
        <div class="col-md-6">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title">Colored Spinner</h5>
            </div>
            <div class="card-body">
              <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <div class="spinner-border text-secondary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <div class="spinner-border text-success" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <div class="spinner-border text-danger" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <div class="spinner-border text-warning" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <div class="spinner-border text-info" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <div class="spinner-border text-light" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <div class="spinner-border text-dark" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>
        <!-- /Colored Spinner -->

        <!-- Grow Spinner -->
        <div class="col-md-6">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title">Grow Spinner</h5>
            </div>
            <div class="card-body">
              <div class="spinner-grow" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>
        <!-- /Grow Spinner -->

        <!-- Colored Grow Spinner -->
        <div class="col-md-6">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title">Colored Grow Spinner</h5>
            </div>
            <div class="card-body">
              <div class="spinner-grow text-primary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <div class="spinner-grow text-secondary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <div class="spinner-grow text-success" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <div class="spinner-grow text-danger" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <div class="spinner-grow text-warning" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <div class="spinner-grow text-info" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <div class="spinner-grow text-light" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <div class="spinner-grow text-dark" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>
        <!-- /Colored Grow Spinner -->

        <!-- Spinner -->
        <div class="col-md-6">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title">Flex Alignment</h5>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-center mb-4">
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
              <div class="d-flex align-items-center justify-content-between">
                <strong>Loading...</strong>
                <div class="spinner-border" role="status" aria-hidden="true"></div>
              </div>
            </div>
          </div>
        </div>
        <!-- /Spinner -->

        <!-- Spinner -->
        <div class="col-md-6">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title">Float Alignment</h5>
            </div>
            <div class="card-body">
              <div class="clearfix">
                <div class="spinner-border float-end mb-4" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <div class="clearfix">
                <div class="spinner-border float-start" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /Spinner -->

        <!-- Spinner -->
        <div class="col-md-6">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title">Alignment Text center</h5>
            </div>
            <div class="card-body">
              <div class="text-center">
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /Spinner -->

        <!-- Alignment Margin -->
        <div class="col-md-6">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title">Alignment Margin</h5>
            </div>
            <div class="card-body">
              <div class="spinner-border m-5" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        </div>
        <!-- /Alignment Margin -->

        <!-- Different Sizes -->
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title">Different Sizes</h5>
            </div>
            <div class="card-body">
              <div class="spinner-border spinner-border-sm" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <div class="spinner-grow spinner-grow-sm mr-3" role="status">
                <span class="sr-only">Loading...</span>
              </div>

              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <div class="spinner-grow mr-3" role="status">
                <span class="sr-only">Loading...</span>
              </div>

              <div class="spinner-border spinner-border-lg" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <div class="spinner-grow spinner-grow-lg" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>
        <!-- /Different Sizes -->

        <!-- Buttons Spinner -->
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title">Buttons</h5>
            </div>
            <div class="card-body">
              <div class="btn-list">
                <button class="btn btn-primary-light" type="button" disabled>
                  <span
                    class="spinner-border spinner-border-sm align-middle"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span class="visually-hidden">Loading...</span>
                </button>
                <button class="btn btn-primary-light" type="button" disabled>
                  <span
                    class="spinner-border spinner-border-sm align-middle"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </button>
                <button class="btn btn-primary-light" type="button" disabled>
                  <span
                    class="spinner-grow spinner-grow-sm align-middle"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span class="visually-hidden">Loading...</span>
                </button>
                <button class="btn btn-primary-light" type="button" disabled>
                  <span
                    class="spinner-grow spinner-grow-sm align-middle"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </button>
                <button class="btn btn-secondary-light" type="button" disabled>
                  <span
                    class="spinner-grow spinner-grow-sm align-middle"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </button>
                <button class="btn btn-success-light" type="button" disabled>
                  <span
                    class="spinner-grow spinner-grow-sm align-middle"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </button>
                <button class="btn btn-info-light" type="button" disabled>
                  <span
                    class="spinner-grow spinner-grow-sm align-middle"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </button>
                <button class="btn btn-warning-light" type="button" disabled>
                  <span
                    class="spinner-grow spinner-grow-sm align-middle"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </button>
                <button class="btn btn-danger-light" type="button" disabled>
                  <span
                    class="spinner-grow spinner-grow-sm align-middle"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </button>
                <button class="btn btn-light" type="button" disabled>
                  <span
                    class="spinner-grow spinner-grow-sm align-middle"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </button>
                <button class="btn btn-dark text-fixed-white" type="button" disabled>
                  <span
                    class="spinner-grow spinner-grow-sm align-middle"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /Buttons Spinner -->
      </div>
    </div>
  </div>
</template>
